import { CalendlyButton } from '@benjaminpetry/sisterhoodmassagen-design'
import { Home } from './HomePage/Home'
import './HomePage.scss'
import { Location } from './HomePage/Location'
import { Massages } from './HomePage/Massages'
import { OpeningHours } from './HomePage/OpeningHours'
import { Promotion } from './HomePage/Promotion'
import { Welcome } from './HomePage/Welcome'
import { Room } from './HomePage/Room'
import { ParentalLeave } from './HomePage/ParentalLeave'

export const HomePage = () => {
  return (
    <main className="c-home-page">
      <Home></Home>
      <CalendlyButton className="c-home-page__calendly-button c-mobile-only"></CalendlyButton>
      <Welcome></Welcome>
      <ParentalLeave></ParentalLeave>
      <Promotion></Promotion>
      <Massages></Massages>
      <OpeningHours></OpeningHours>
      <Room></Room>
      <Location></Location>
    </main>
  )
}
