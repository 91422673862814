import { Assets, Botch, BotchType, Image, Separator } from '@benjaminpetry/sisterhoodmassagen-design'
import { Texts } from '../../data/texts'
import './ParentalLeave.scss'

const VoucherImage = Assets.folder('illustrations').imageSet(Texts.ParentalLeave.Image, Texts.ParentalLeave.Alt)

export const ParentalLeave = () => {
  const parseText = (text: string) => {
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>
  }

  return (
    <section className="c-parental-leave">
      <div className='c-parental-leave__wrapper'>
        <div className='c-parental-leave__illustration-container'>
          <Image className='c-parental-leave__image' {...VoucherImage}></Image>
        </div>
        <div className='c-parental-leave__content'>
          <h3 className='c-parental-leave__title'>{Texts.ParentalLeave.Title}</h3>
          <div className='c-parental-leave__text'>
            <Botch className='c-parental-leave__botch' botchType={BotchType.OrangePink}></Botch>
            {Texts.ParentalLeave.Text.map((item, index) => {
              return <p key={index} className={`c-parental-leave__paragraph--${item.highlight ? 'highlight' : 'normal'}`}>{parseText(item.text)}</p>
            })}
          </div>
        </div>
      </div>
      <Separator sizeAppearance={'small'}></Separator>
    </section>
  )
}
